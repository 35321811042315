<template>
  <div
    class="fixed top-0 w-full h-screen bg-black bg-opacity-25 z-50"
    aria-hidden="true"
  >
    <div class="md:w-2/3 h-full relative" @click="$emit('closeMenu')"></div>
    <div
      class="fixed top-0 left-0 z-45 h-screen w-full md:w-72 bg-white border-solid border-t-4 border-primary p-8"
    >
      <div class="flex justify-end">
        <svg
          class="w-6 h-6 hover:text-red-200 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="$emit('closeMenu')"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div class="mt-10">
        <h1 class="text-4xl text-primary">{{ $t("menu") }}</h1>
      </div>
      <div class="mt-8">
        <ul class="flex flex-col">
          <li>
            <router-link
              class="py-2 px-4 block tracking-wide border rounded hover:bg-primary hover:text-white hover:border-primary"
              to="/feed"
            >
              {{ $t("feed") }}
            </router-link>
          </li>
          <li class="mt-2">
            <router-link
              class="py-2 px-4 block tracking-wide border rounded hover:bg-primary hover:text-white hover:border-primary"
              to="/diary"
            >
              {{ $t("calendar") }}
            </router-link>
          </li>
          <li class="mt-2">
            <router-link
              class="py-2 px-4 block tracking-wide border rounded hover:bg-primary hover:text-white hover:border-primary"
              to="/dishes"
            >
              {{ $t("dishes") }}
            </router-link>
          </li>
          <li class="mt-2">
            <router-link
              class="py-2 px-4 block tracking-wide border rounded hover:bg-primary hover:text-white hover:border-primary"
              to="/categories"
            >
              {{ $t("categories") }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarMenu",
  emits: ["closeMenu"]
};
</script>

<style scoped>
.router-link-active {
  color: #8fba92;
  border-color: #8fba92;
}
</style>