<template>
  <div class="h-screen w-full flex flex-col">
    <Flash v-if="showFlash" :message="flashMessage" :type="flashType"></Flash>
    <SidebarSettings v-if="showSettings" @closeSettings="toggleSettings" />
    <SidebarMenu v-if="showMenu" @closeMenu="toggleMenu"></SidebarMenu>
    <header v-if="this.$route.meta.navigation" class="sticky top-0">
      <nav class="p-2 border-b w-full bg-white z-30">
        <div class="flex items-center">
          <div class="w-1/3 flex items-center mr-3 md:pl-3">
            <div class="mr-3">
              <svg
                @click="toggleMenu"
                class="fill-current w-6 h-6 cursor-pointer text-gray-900"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <router-link to="/diary">
              <img
                class="md:h-12"
                src="@/assets/wohnzimmer-kantine-logo.png"
                alt="wohnzimmer-kantine.de Logo"
              />
            </router-link>
          </div>
          <div class="flex-grow md:w-1/3 flex items-center justify-center mr-1 md:mr-0">
            <Search />
          </div>
          <div class="md:w-1/3 flex items-center justify-end">
            <span
              @click="toggleDropDown"
              class="border rounded-full text-gray-400 hover:bg-primary hover:text-white cursor-pointer"
            >
              <img
                v-if="user.image"
                class="rounded-full h-9 w-9 cursor-pointer"
                :src="`https://d1916o647hwu0n.cloudfront.net/${user.image}`"
                alt="Profile Picture"
              />
              <svg
                v-else
                class="w-7 h-7 text-gray-700"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
            <div
              v-if="showDropDown"
              class="z-40 rounded absolute bg-white border right-4 mt-20 md:mt-40"
            >
              <ul class="w-40">
                <li
                  class="py-2 px-4 hover:bg-primary hover:text-white rounded-t cursor-pointer border-b flex items-center"
                  @click="goToProfile"
                >
                  <svg
                    class="w-5 h-5 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span>{{ $t("profile") }}</span>
                </li>
                <li
                  class="py-2 px-4 hover:bg-primary hover:text-white cursor-pointer border-b flex items-center"
                  @click="toggleSettings"
                >
                  <svg
                    class="w-5 h-5 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>{{ $t("settings") }}</span>
                </li>
                <li
                  class="py-2 px-4 hover:bg-primary hover:text-white rounded-b cursor-pointer flex items-center"
                  @click="logout"
                >
                  <span>{{ $t("logout") }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
    <slot class="flex-1" name="content" />
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import Flash from "../components/Flash";
import SidebarSettings from "../components/SidebarSettings";
import SidebarMenu from "../components/SidebarMenu";
import Search from "@/components/Search";

export default {
  name: "Layout",
  components: { Flash, SidebarSettings, SidebarMenu, Search },
  setup() {
    const showDropDown = ref(false);
    const router = useRouter();
    const showFlash = ref(false);
    const flashMessage = ref("");
    const flashType = ref("");
    const showSettings = ref(false);
    const showMenu = ref(false);
    const user = JSON.parse(window.localStorage.getItem("user"));

    window.emitter.on("toggleFlash", (e) => flash(e));

    const flash = (data) => {
      flashMessage.value = data.message;
      flashType.value = data.type;
      showFlash.value = true;

      setTimeout(() => {
        flashMessage.value = "";
        flashType.value = "";
        showFlash.value = false;
      }, 2500);
    };

    const toggleDropDown = () => (showDropDown.value = !showDropDown.value);
    const toggleSettings = () => {
      showSettings.value = !showSettings.value;
      showDropDown.value = false;
    };
    const toggleMenu = () => (showMenu.value = !showMenu.value);
    const logout = async () => {
      await window.axios.post("/logout");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("meals");
      window.localStorage.removeItem("user");
      await router.push("/");
    };

    const goToProfile = () => {
      router.push("/" + user.username);
    };

    return {
      showDropDown,
      showFlash,
      flashMessage,
      flashType,
      showSettings,
      showMenu,
      user,
      flash,
      toggleDropDown,
      goToProfile,
      toggleSettings,
      toggleMenu,
      logout,
    };
  },
};
</script>
