<template>
  <div
    class="fixed top-0 w-full h-screen bg-black bg-opacity-25 z-50"
    aria-hidden="true"
  >
    <div class="md:w-2/3 h-full relative" @click="$emit('closeSettings')"></div>
    <div
      class="fixed overflow-y-auto top-0 right-0 z-45 h-screen w-full md:w-1/3 bg-white border-solid border-t-4 border-primary p-8"
    >
      <div>
        <svg
          class="w-6 h-6 hover:text-red-200 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="$emit('closeSettings')"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div class="mt-10">
        <h1 class="text-4xl text-primary">{{ user.username }}</h1>
        <div class="mt-1 flex">
          <span class="mr-3"
            ><strong>{{ user.follower ? user.follower.length : "0" }}</strong>
            {{ $t("follower") }}</span
          >
          <span
            ><strong>{{ user.follows ? user.follows.length : "0" }}</strong>
            {{ $t("follows") }}</span
          >
        </div>
        <div class="mt-6">
          <form
            action="post"
            class="mt-4"
            enctype="multipart/form-data"
            @submit.prevent="updateSettings"
          >
            <div class="form-block pb-4 border-b">
              <label class="block text-sm font-medium text-gray-700">
                Photo
              </label>
              <div class="mt-1 flex items-center">
                <span
                  class="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100"
                >
                  <div v-if="user.image" class="rounded-full">
                    <img
                      class="rounded-full h-12 w-12"
                      :src="`https://d1916o647hwu0n.cloudfront.net/${user.image}`"
                      alt="Profile Picture"
                    />
                  </div>
                  <svg
                    v-else
                    class="h-full w-full text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                </span>
                <input
                  type="file"
                  @change="addImage"
                  class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50"
                />
              </div>
            </div>
            <div class="form-block mt-4">
              <label
                for="username"
                class="block text-sm font-medium text-gray-700"
                >{{ $t("username") }}</label
              >
              <input
                type="text"
                id="username"
                name="username"
                v-model="user.username"
                class="mt-1 block w-full shadow-sm border border-gray-300 rounded-md px-2 py-2 text-gray-700"
              />
            </div>
            <div class="form-block mt-4">
              <label
                for="name"
                class="block text-sm font-medium text-gray-700"
                >{{ $t("name") }}</label
              >
              <input
                type="text"
                id="name"
                name="name"
                v-model="user.name"
                class="mt-1 block w-full shadow-sm border border-gray-300 rounded-md px-2 py-2 text-gray-700"
              />
            </div>
            <div class="form-block mt-4">
              <label
                for="email"
                class="block text-sm font-medium text-gray-700"
                >{{ $t("email") }}</label
              >
              <input
                type="email"
                id="email"
                name="email"
                v-model="user.email"
                class="mt-1 block w-full shadow-sm border border-gray-300 rounded-md px-2 py-2 text-gray-700"
              />
            </div>
            <div class="form-block mt-4">
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
                >{{ $t("password") }}</label
              >
              <input
                type="password"
                id="password"
                name="password"
                v-model="user.password"
                placeholder="*********"
                class="mt-1 block w-full shadow-sm border border-gray-300 rounded-md px-2 py-2 text-gray-700"
              />
            </div>
            <h3 class="mt-4 pt-2 border-t">{{ $t("active-meals") }}</h3>
            <div class="form-block mt-4 flex">
              <div
                class="flex items-center mr-4"
                v-for="meal in timeAtDay"
                :key="meal"
              >
                <input
                  :id="'meal-' + meal"
                  name="meals"
                  v-model="user.meals"
                  :value="meal"
                  type="checkbox"
                  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
                <div class="ml-2 text-sm">
                  <label for="meals" class="font-medium text-gray-700">{{
                    $t(meal)
                  }}</label>
                </div>
              </div>
            </div>
            <div class="form-block mt-6">
              <button type="submit" class="btn-primary">
                {{ $t("save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "SidebarSettings",
  setup() {
    const timeAtDay = ["breakfast", "lunch", "dinner"];
    const user = ref(JSON.parse(window.localStorage.getItem("user")));
    const image = ref(null);
    const router = useRouter();

    const addImage = (event) => {
      image.value = event.target.files[0];
    };

    const updateSettings = async () => {
      let formData = new FormData();
      formData.append("file", image.value);
      formData.append("user", JSON.stringify(user.value));

      try {
        const response = await window.axios.post("/users/settings", formData);

        window.localStorage.setItem("meals", JSON.stringify(user.value.meals));
        window.localStorage.setItem("user", JSON.stringify(response.data.user));

        window.emitter.emit("toggleFlash", {
          message: response.data.message,
          type: response.data.type,
        });

        window.emitter.emit("updateMeals", {
          meals: JSON.stringify(user.value.meals),
        });
      } catch (error) {
        if (error && error.response.status === 401) {
          window.localStorage.removeItem("token");
          router.push("/");
        } else {
          window.emitter.emit("toggleFlash", {
            message: "error",
            type: "error",
          });
        }
      }
    };

    return {
      user,
      timeAtDay,
      updateSettings,
      addImage,
    };
  },
};
</script>
