<template>
  <div class="flex items-center relative w-full md:w-80">
    <span
      class="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-700"
    >
      <svg
        class="fill-current w-4 h-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clip-rule="evenodd"
        />
      </svg>
    </span>
    <input
      type="text"
      class="py-2 pl-8 text-gray-700 text-sm border w-full shadow-sm rounded focus:outline-none"
      :placeholder="$t('search')"
      v-model="term"
      @keyup="search"
    />
    <div
      class="absolute z-10 px-2 w-screen max-w-sm top-10 transform left-1/2 -translate-x-1/2"
      v-if="showSuggestions"
    >
      <div
        class="rounded shadow-lg overflow-x-hidden overflow-y-scroll border bg-white py-2"
      >
        <ul>
          <li
            v-show="suggestions.length > 0"
            v-for="user in suggestions"
            :key="user._id"
            class="flex items-center hover:bg-gray-50 px-4 py-2 cursor-pointer"
            @click="openProfile(user.username)"
          >
            <div class="border rounded-full">
              <div class="h-9 w-9 rounded-full border">
                <img
                  v-if="user.image"
                  class="rounded-full h-9 w-9"
                  :src="`https://d1916o647hwu0n.cloudfront.net/${user.image}`"
                  alt="Profile Picture"
                />
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
            <div class="ml-3 flex flex-col">
              <span class="text-sm font-semibold">{{ user.username }}</span>
              <span class="text-sm text-gray-400">{{ user.name }}</span>
            </div>
          </li>
          <li
            v-show="suggestions.length === 0"
            class="flex items-center hover:bg-gray-50 px-4 py-2 cursor-pointer"
          >
            <span>{{ $t("nothing-found") }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "Search",
  setup() {
    const term = ref("");
    const suggestions = ref([]);
    const showSuggestions = ref(false);
    const router = useRouter();

    const search = async () => {
      if (term.value.length >= 3) {
        try {
          const { data } = await window.axios.post("/users/search", {
            term: term.value,
          });

          suggestions.value = data;
          showSuggestions.value = true;
        } catch (error) {
          if (error && error.response.status === 401) {
            window.localStorage.removeItem("token");
            router.push("/");
          } else {
            window.emitter.emit("toggleFlash", {
              message: "error",
              type: "error",
            });
          }
        }
      } else {
        showSuggestions.value = false;
      }
    };

    const openProfile = (username) => router.push("/" + username);

    return {
      term,
      suggestions,
      showSuggestions,
      search,
      openProfile,
    };
  },
};
</script>

<style>
</style>